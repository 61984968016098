const GEN_COOKIE_4AI_JS_URI = 'scripts/cookie_4ai.080b3905.js';
const GEN_COOKIE_4AI_CSS_URI = 'styles/cookie/cookie_4ai.c2da5cc2.css';
const GEN_BOBBY_JS_URI = 'scripts/bobby.443bbace.js';
const GEN_WEBPUSH_JS_URI = 'scripts/webpush.0cb854f8.js';
const GEN_OFFLINE_JS_URI = 'scripts/offline.22b00d7b.js';

function loadCookieConsent() {
    return new loadExt([
        assetUrl(GEN_COOKIE_4AI_JS_URI),
        assetUrl(GEN_COOKIE_4AI_CSS_URI)
    ], () => {
        return new cookieConsentJS();
    });
}

function getDefaultsGa() {
    let defaults = {
        'dimension1': 'online',
        'dimension2': 'proxied',
        // send available width x height (not absolute)
        'ScreenResolution': `${window.screen.availWidth}x${window.screen.availHeight}`,
        'ViewportSize': `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`,
        //'DocumentEncoding': document.inputEncoding,
        'UserLanguage': navigator.language || navigator.userLanguage,
        'DocumentTitle': document.title,
        'UserAgentOverride': navigator.userAgent
        /*
        'PageLoadTime': window.performance.timing.loadEventEnd - window.performance.timing.navigationStart,
        'DnsTime': window.performance.timing.domainLookupEnd - window.performance.timing.domainLookupStart,
        'PageDownloadTime': window.performance.timing.responseEnd - window.performance.timing.requestStart,
        'ServerResponseTime': window.performance.timing.responseStart - window.performance.timing.requestStart,
        'DomInteractiveTime': window.performance.timing.domInteractive - window.performance.timing.navigationStart,
        'SessionControl': undefined,
        'UserTimingCategory': undefined,
        'UserTimingVariableName': undefined,
        'UserTimingTime': undefined,
        'UserTimingLabel': undefined,
        'GeographicalOverride': undefined,
        */
    };

    // campaign info
    if (location.search) {
        const qs = new URLSearchParams(location.search);
        const keys = {
            utm_id: 'CampaignId',
            utm_source: 'CampaignSource',
            utm_medium: 'CampaignMedium',
            utm_campaign: 'CampaignName',
            utm_term: 'CampaignKeyword',
            utm_content: 'CampaignContent'
        };
        for (const [utmkey, trackkey] of Object.entries(keys)) {
            if (qs.has(utmkey)) {
                defaults[trackkey] = qs.get(utmkey);
            }
        }
    }

    return Object.assign({}, defaults, js_params.bobby_data);
}

function loadBobby() {

    return new loadExt([
        assetUrl(GEN_BOBBY_JS_URI)
    ], () => {
        gap('send', 'pageview', getDefaultsGa());
    });
}

function loadGoogleAnalytics() {

    /* DEFAULT FROM GOOGLE
    loadExt(['https://www.googletagmanager.com/gtag/js?id=' + js_params.ga_uid + '.js']);
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', js_params.ga_uid);*/

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    return new loadExt([
        'https://www.googletagmanager.com/gtag/js?id=' + js_params.ga_uid + '.js'
    ], () => {
        console.debug("GTAG : Google tag manager initié avec l'id suivant : " + js_params.ga_uid);

        // Test gaData to acknowledge adblocker/anti-tracking usage
        if(window.gaData !== undefined || window.google_tag_manager !== undefined) {
            // Set default value of custom dimension 1 to 'online' (GA4)
            gtag('config', js_params.ga_uid, {
                'siteSpeedSampleRate': js_params.siteSpeedSampleRate || 1,
                'dimension1': 'online'
            });
        } else {
            // If consent OK but adblocker active, server method triggered
            console.debug("Server hit");
            loadBobby();
        }
    });
}

function loadWebPush() {
    return new loadExt([
        assetUrl(GEN_WEBPUSH_JS_URI)
    ], async () => {
        webPushInit();
    });
}

function loadServiceWorker() {
    const env = window.location.host === 'www.artwai.com' ? 'prod' : 'dev';
    return new loadExt([
        assetUrl(`lib/workbox/workbox-window.${env}.umd.js`),
        assetUrl(GEN_OFFLINE_JS_URI)
    ], async () => {
        if ('serviceWorker' in navigator) {
            const wb = new workbox.Workbox('/workbox_sw.js');

            wb.addEventListener('waiting', () => {
                new createUIPrompt(
                    'app-update',
                    'La PWA artwai.com doit effectuer une mise à jour.',
                    {
                        onAccept: async () => {
                            wb.messageSW({type: 'CLEAN_CACHE'});

                            wb.addEventListener('controlling', () => {
                                window.location.reload();
                            });

                            wb.messageSW({type: 'SKIP_WAITING'});
                        }
                    }).show();
            });

            wb.register();
            this.dispatchEvent(new CustomEvent('wbregistered', {detail: {workbox: wb}}));
        }
    });
}

window.addEventListener('load', async () => {
    if (is_service_worker_enabled()) {
        loadServiceWorker();
    }
});

window.addEventListener('load', async () => {
    if (is_webpush_available()) {
        loadWebPush();
    }
});

window.addEventListener('load', async () => {
    let consentStatsObj = readCookie('consent_statistiques');
    let consentStats = '';
    if(consentStatsObj != false) {
        consentStats = JSON.parse(consentStatsObj);
    }

    if (consentStatsObj === false) {
        loadCookieConsent();
        if (js_params.ga_proxy) {
            loadBobby();
        }
    } else if (consentStats.value !== 1 && js_params.ga_proxy) {
        loadBobby();
    } else if (js_params.ga_enabled) {
        loadGoogleAnalytics();
    }
});

window.addEventListener('load', async () => {
    if (!('indexedDB' in window)) {
        a4ilog.warn('This browser doesn\'t support IndexedDB. Perfomances may be impacted');
        return;
    }

    const db_name = 'memshare';
    const db_version = 2;

    let idb = window.indexedDB;

    // Open a db instance
    let request = idb.open(db_name, db_version);

    request.onerror = (event) => a4ilog.error('Database error: ' + event.target.errorCode);

    // Create or update the database structure
    request.onupgradeneeded = (event) => {
        const db = event.target.result;

        const offlineStore = db.createObjectStore(db_name, {keyPath: 'key'});
        offlineStore.createIndex('value', 'value');
    };

    request.onsuccess = (event) => {
        // Get db instance
        const db = event.target.result;

        // Open a transaction
        const transaction = db.transaction([db_name], 'readwrite');

        // Select offline-pages object store (table)
        const memshare = transaction.objectStore(db_name);
        const connected = memshare.get('connected');

        connected.onsuccess = () => {
            const connected = document.cookie.search('user_connected') >= 0;
            memshare.put({key: 'connected', value: connected});
        }
    };
});